.Home {
  text-align: center;
  /* color: rgb(11, 75, 143); */
  /* color: rgb(183, 63, 191); */
  color: rgb(14, 216, 227);
  background-color: #F8F8FF;
}


h1{
  font-Family: 'Aladin', cursive;
  -webkit-text-stroke: 1px black;
  text-shadow: 2px 2px 2px black;
}

p{
  color:black;
}

.navbar, .navbar-brand, .navbar-end, .navbar-menu, .navbar-start {
  padding-top: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  align-items: stretch !important;
  display: flex !important;
  font-Family: 'Aladin', cursive;
  background-color: black;
}

.navbar-menu {
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.navbar-item {
  margin-top: 10px;
  margin-left: 10px;
  color: white;
  display: flex !important;
}

.navbar-item:hover{
  cursor: pointer;
  text-decoration: none;
  color: rgb(14, 216, 227);
}

.navbar-end {
  justify-content: flex-end !important;
  margin-left: auto !important;
}


.footer{
  font-family: sans-serif;
  color: white;
  background-color: black;
}
